import { FlashMessages, Notification } from "@ui/FlashMessages";
import { ReactNode, createRef } from "react";

const flashMessagesRef = createRef<FlashMessages>();

export const getFlashMessagesRef = () => {
    return flashMessagesRef;
};

function flashMessage(notification: Notification) {
    if (flashMessagesRef.current) {
        flashMessagesRef.current.open(notification);
    }
}

function closeFlashMessageById(id: Notification["id"]) {
    if (flashMessagesRef.current) {
        flashMessagesRef.current.closeById(id);
    }
}

function openPermanentComponent(Component: ReactNode) {
    if (flashMessagesRef.current) {
        flashMessagesRef.current.openPermanent(Component);
    }
}

function closePermanentComponent() {
    if (flashMessagesRef.current) {
        flashMessagesRef.current.closePermanent();
    }
}

export const successMessages = (message: string) => {
    return flashMessage({ title: message, variant: "primary" });
};

export const error = (message: string) => {
    return flashMessage({ title: message, variant: "danger" });
};

export const FlashMessagesService = {
    flashMessage,
    closeFlashMessageById,
    successMessages,
    error,
    openPermanentComponent,
    closePermanentComponent,
};

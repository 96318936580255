import { useMyProfileQuery } from "@gql-schema";
import { useRequestEmailVerificationMutation } from "@hooks/mutations/useRequestEmailVerificationMutation";
import { useRefreshQuery } from "@hooks/queries/useRefreshQuery";
import { useMeNullable } from "@profile-zone/hooks/use-me-nullable";
import { ErrorService } from "@services/ErrorService";
import { FlashMessagesService } from "@services/FlashMessagesService";
import { useTranslation } from "@translations/use-translation";
import { Notification } from "@ui/FlashMessages";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import LogRocket from "logrocket";
import { useCallback, useEffect } from "react";

let flashMessageViewed = false;

const NOTIFICATION_ID: Notification["id"] = "user-verify-email";

function Content() {
    useRefreshQuery();
    const { t } = useTranslation();

    const { data } = useMyProfileQuery();

    const [requestEmailVerificationMutation] = useRequestEmailVerificationMutation();

    useEffect(() => {
        if (!data?.me) {
            return;
        }

        LogRocket.identify(data.me.id.toString(), {
            name: data.me.fullName,
            email: data.me.email || "",
        });
    }, [data?.me]);

    const requestEmailVerification = useCallback(async () => {
        try {
            await requestEmailVerificationMutation({});

            FlashMessagesService.flashMessage({
                title: t("component-core-user-services:link-sent"),
                variant: "primary",
            });
        } catch (e) {
            ErrorService.handleError(e);
        }
    }, [requestEmailVerificationMutation, t]);

    useEffect(() => {
        if (!data?.me) {
            return;
        }

        const isEmailVerified = data.me.emailVerified;

        // If the flash message is shown and the email is verified, close the flash message
        if (flashMessageViewed && isEmailVerified) {
            FlashMessagesService.closeFlashMessageById(NOTIFICATION_ID);
            flashMessageViewed = false;
        }
        // If the flash message is not shown and the email is not verified, show the flash message
        else if (!flashMessageViewed && !isEmailVerified) {
            FlashMessagesService.flashMessage({
                id: NOTIFICATION_ID,
                variant: "text",
                autoDismiss: false,
                body: t("component-core-user-services:verify-account"),
                title: t("component-core-user-services:warning-about-email"),
                button: t("component-core-user-services:send-verify-email"),
                buttonProps: { onClick: requestEmailVerification },
            });
            flashMessageViewed = true;
        }
    }, [data, requestEmailVerification, t]);

    return null;
}

export function LoggedUserServices() {
    const me = useMeNullable();

    return isNotNil(me) ? <Content /> : null;
}
